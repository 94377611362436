// About.js

import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import Header from "./Header";

function AddLink() {
    const navigate = useNavigate();
    const handleLogout = () => {
        // Perform any logout logic (e.g., clear tokens, session, etc.)
        // Then navigate to the root path
        navigate('/');
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const apiUrl = process.env.REACT_APP_API_URL+'/api/add';
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
          if (response.ok) {
            // Handle success (e.g., show a success message)
            const responseData = await response.json();
            console.log(responseData.message);
          } else {
            // Handle error (e.g., show an error message)
          }
        } catch (error) {
          // Handle network error
        }
      };
      const [formData, setFormData] = useState({
        name: '',
        link: '',
        // Add other form fields here
      });

  return (
    <div>
        <Header />
      <h1>Add a link</h1>
      <p>Here, you can view important information and manage your account.</p>
      {/* Add more components, charts, or widgets as needed */}
      <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
      />
      <input
        type="text"
        name="email"
        value={formData.link}
        onChange={(e) => setFormData({ ...formData, link: e.target.value })}
      />
      {/* Add other form fields */}
      <button type="submit">Submit</button>
    </form>
      {/* Logout button */}
      <button onClick={handleLogout}>Logout</button>
      
    </div>
  );
}

export default AddLink;
