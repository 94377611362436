// About.js

import React, { useState} from "react";
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import Header from "./Header";

function About() {
    const [apiResponse, setApiResponse] = useState(''); // Initialize with an empty string
    const navigate = useNavigate();
    const handleLogout = () => {
        // Perform any logout logic (e.g., clear tokens, session, etc.)
        // Then navigate to the root path
        navigate('/');
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const apiUrl = process.env.REACT_APP_API_URL+'/api/chat';
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
          if (response.ok) {
            // Handle success (e.g., show a success message)
            const responseData = await response.json();
            console.log(responseData.response);
            setApiResponse(responseData.response);
          } else {
            // Handle error (e.g., show an error message)
          }
        } catch (error) {
          // Handle network error
        }
      };
      const [formData, setFormData] = useState({
        message: '',
        
        // Add other form fields here
      });
      
  return (
    <div>
        <Header />
      <h1>About useForm</h1>
      <p>Ask me anything.</p>
      <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="message"
        value={formData.message}
        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
      />
      
      {/* Add other form fields */}
      <button type="submit">Submit</button>
    </form>
    <div>
      {/* Display the API response */}
      <h1>Wisdom Response:</h1>
      <p>{apiResponse}</p>
    </div>
      {/* Logout button */}
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
}

export default About;
