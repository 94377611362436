// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginForm from "./components/LoginForm"; // Import your login form component
import Dashboard from "./components/Dashboard"; // Import your dashboard component
import About from "./components/About";
import AddLink from "./components/AddLink";
import User from "./components/User";
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path="/" element={<LoginForm/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/addLink" element={<AddLink/>} />
          <Route path="/user" element={<User/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
