// src/components/LoginButton.js
import React from 'react';

const LoginButton = () => {
  const handleLogin = () => {
    // Redirect the user to your server endpoint for TikTok login
    const apiUrl = process.env.REACT_APP_API_URL+'/api/tiktok';

    window.location.href = apiUrl; // Replace with your actual server endpoint
  };

  return (
    <button onClick={handleLogin}>Continue with TikTok</button>
  );
};

export default LoginButton;
