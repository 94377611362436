// Dashboard.js

import React from "react";
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import Header from "./Header";

function Dashboard() {
    const navigate = useNavigate();
    const handleLogout = () => {
        // Perform any logout logic (e.g., clear tokens, session, etc.)
        // Then navigate to the root path
        navigate('/');
      };

  return (
    <div>
        <Header />
      <h1>Welcome to Your Dashboard</h1>
      <p>Here, you can view important information and manage your account.</p>
      {/* Add more components, charts, or widgets as needed */}
      {/* Logout button */}
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
}

export default Dashboard;
