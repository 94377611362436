// Header.js
import React from 'react';
import { NavLink } from 'react-router-dom';

const Header = () => {
  return (
    <nav>
      <ul>
        <li>
          <NavLink to="/dashboard">Home</NavLink>
        </li>
        <li>
          <NavLink to="/about">About</NavLink>
        </li>
        <li>
          <NavLink to="/addLink">Add Link</NavLink>
        </li>
        <li>
          <NavLink to="/user">User</NavLink>
        </li>

      </ul>
    </nav>
  );
};

export default Header;
