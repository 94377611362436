
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom"; // Import useHistory
import LoginButton from '../components/LoginButton';

function LoginForm() {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate(); // Initialize useHistory
  
  const [loginError, setLoginError] = useState(null); // State for login error message

  const onSubmit = (data) => {
    // Validate form data (you can add your validation logic here)
    if (data.email && data.password) {
      // Assuming validation passes, redirect to the dashboard
      navigate("/dashboard");
    } else {
      // Handle validation errors (e.g., show error messages)
      setLoginError("Error en login");
      console.log("Form data is incomplete.");
    }
  };

  return (
    <div>
    <form onSubmit={handleSubmit(onSubmit)}>
      <input {...register("email")} placeholder="Email" />
      <input {...register("password")} type="password" placeholder="Password" />
      <button type="submit">Sign In</button>
      {/* Display login error message */}
      {loginError && <p style={{ color: 'red' }}>{loginError}</p>}
    </form>
    <a href={process.env.REACT_APP_API_URL+'/api/tiktok'}>Continue with TikTok</a>
    <LoginButton />
    </div>
  );
}

export default LoginForm;