import React, { useState } from 'react';
import Header from "./Header";


const User = () => {
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const userProfile = async (username) => {
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL+'/api/user/'+username;
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        // Handle success (e.g., show a success message)
        const responseData = await response.json();
        console.log(responseData.message);
        console.log(responseData.json.userInfo);
        const userData = {
          username : responseData.json.userInfo.user.uniqueId,
          realName : responseData.json.userInfo.user.nickname,
        };
        console.log(userData);
        setUserData(userData);
      } else {
        // Handle error (e.g., show an error message)
      }
    } catch (error) {
      console.error('Error fetching TikTok profile:', error);
      throw error; // Rethrow the error or handle it as needed
    } finally {
      setIsLoading(false);
    }
  }

  

  return (
    <div>
    <Header />
      <h1>TikTok Profile</h1>
      <button onClick={()=>(userProfile('lilyachty'))}>Get User</button>
      { isLoading ? (
        <p>Loading...</p>
      ) : (
        <table>
        <tbody>
          <tr>
            <td>Username:</td>
            <td>{userData.username}</td>
          </tr>
          <tr>
            <td>Real Name:</td>
            <td>{userData.realName}</td>
          </tr>
          {/* Add more rows for other user details */}
        </tbody>
      </table>
        )
      }
      
    </div>
  );
};

export default User;
